<template>
  <div>
    <div class="panel payments-container">
      <div class="column" align="left">
        <el-form label-position="top" :inline="true" size="mini" v-if="count">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="Хайх" style="width: 100%">
                <el-input
                  @input="getLog('0', '20')"
                  v-model="search_text"
                  size="mini"
                  placeholder="Захиалгын дугаараа оруулна уу"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Огноо">
                <div class="block">
                  <el-date-picker
                    @change="date()"
                    v-model="datePicker"
                    type="datetimerange"
                    :picker-options="pickerOptions"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    range-separator="-"
                    start-placeholder="Эхлэх огноо"
                    end-placeholder="Дуусах огноо"
                    align="right"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="Төлөв">
                <span class="mr10 mt-20">Хүлээн авсан</span>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="Нийт">
                <span>
                  <el-tag type="success" effect="dark" size="medium">
                    {{ count.assigned_count.assignedCount }}
                  </el-tag>
                </span>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="Ашигтай">
                <div class="block">
                  <el-tag type="success" effect="dark" size="medium">
                    {{ count.assigned_count.rule1 }}
                  </el-tag>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="Гялалзуур">
                <div class="block">
                  <el-tag type="success" effect="dark" size="medium">
                    {{ count.assigned_count.rule2 }}
                  </el-tag>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="Зам зуур">
                <div class="block">
                  <el-tag type="success" effect="dark" size="medium">
                    {{ count.assigned_count.rule3 }}
                  </el-tag>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="Арга ядсан">
                <div class="block">
                  <el-tag type="success" effect="dark" size="medium">
                    {{ count.assigned_count.rule4 }}
                  </el-tag>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item>
                <span class="mr30">Цуцалсан</span>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item>
                <span class="block">
                  <el-tag type="danger" effect="dark" size="medium">
                    {{ count.cancel_count.cancelCount }}
                  </el-tag>
                </span>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item>
                <div class="block">
                  <el-tag type="danger" effect="dark" size="medium">
                    {{ count.cancel_count.rule1 }}
                  </el-tag>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item>
                <div class="block">
                  <el-tag type="danger" effect="dark" size="medium">
                    {{ count.cancel_count.rule2 }}
                  </el-tag>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item>
                <div class="block">
                  <el-tag type="danger" effect="dark" size="medium">
                    {{ count.cancel_count.rule3 }}
                  </el-tag>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item>
                <div class="block">
                  <el-tag type="danger" effect="dark" size="medium">
                    {{ count.cancel_count.rule4 }}
                  </el-tag>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item>
                <span class="">Нийт санал болгосон тоо</span>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-tag type="warning" effect="dark" size="medium">
                {{ count.all_suggest_count }}
              </el-tag>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <el-table :data="data" size="mini" style="width: 95%,margin: 100vh">
        <el-table-column
          prop="order_number"
          label="Захиалгын дугаар"
        ></el-table-column>
        <el-table-column
          prop="assigned_start_time"
          label="Санал болгосон огноо"
        ></el-table-column>
        <el-table-column label="Дэлгэрэнгүй">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="logDetail(scope.row)"
              style="margin-top: 10px;"
              >Дэлгэрэнгүй үзэх</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="text-right mt10"
        background
        :page-size="pageSize"
        :current-page.sync="currentPage"
        :page-sizes="[5, 20, 50, 100]"
        layout="total, sizes, prev, pager, next"
        @size-change="sizeChangeHandler"
        @current-change="curentPageHandler"
        :total="totalCount"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import service from "../helpers/services";
export default {
  data() {
    return {
      pageSize: 20,
      count: 0,
      search_text: "",
      currentPage: 1,
      totalCount: 0,
      data: null,
      pickerOptions: {
        shortcuts: [
          {
            text: "7 хоног",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "1 сар",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "3 сар",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      datePicker: null
    };
  },
  async mounted() {
    await this.currentDateTime();
    await this.suggestLog();
  },
  methods: {
    currentDateTime() {
      const current = new Date();
      const date =
        current.getFullYear() +
        "-" +
        (current.getMonth() + 1) +
        "-" +
        current.getDate();
      const time = "00:00:00";
      const dateTime = date + " " + time;
      const tommorowDate =
        current.getFullYear() +
        "-" +
        (current.getMonth() + 1) +
        "-" +
        (current.getDate() + 1);
      const tommorowTime = "00:00:00";
      const tommorow = tommorowDate + " " + tommorowTime;
      const getTime = [];
      getTime.push(dateTime, tommorow);
      this.datePicker = this.$route.query.date
        ? this.$route.query.date
        : getTime;
    },
    date() {
      this.getLog(this.from, this.size);
    },
    suggestLog() {
      var from = this.pageSize * (this.currentPage - 1);
      var size = this.pageSize;
      if (this.$route.query.page && this.$route.query.size) {
        const incomingPage = this.$route.query.page
          ? parseFloat(this.$route.query.page)
          : 0;
        const incomingSize = parseFloat(this.$route.query.size);
        this.currentPage = incomingPage;
        this.pageSize = incomingSize;
        from = this.pageSize * (incomingPage - 1);
        size = incomingSize;
      }
      this.from = from;
      this.size = size;
      this.getLog(from, size);
    },
    sizeChangeHandler(item) {
      this.pageSize = item;
      this.$router.push({
        name: "SuggestLog",
        query: { page: this.currentPage, size: this.pageSize }
      });
      this.getLog(this.pageSize * (this.currentPage - 1), this.pageSize);
    },
    curentPageHandler(item) {
      this.currentPage = item;
      this.$router.push({
        name: "SuggestLog",
        query: { page: this.currentPage, size: this.pageSize }
      });
      this.getLog(this.pageSize * (this.currentPage - 1), this.pageSize);
    },
    getLog(from, size) {
      if (this.datePicker !== null) {
        const body = {
          search_text: this.search_text,
          size: size,
          from: from,
          start_date: this.datePicker[0],
          end_date: this.datePicker[1]
        };
        const query = "";
        service.getLog(query, body).then(response => {
          this.data = response.data;
          this.totalCount = response.total;
          this.count = response;
        });
      } else {
        this.data = null;
        this.alertReport(
          "Анхаар",
          "Эхлэх болон дуусах огноог оруулна уу!",
          "warning"
        );
      }
    },
    alertReport(title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type
      });
    },
    logDetail(data) {
      const body = {
        order_refer: data.order_refer
      };
      service.getLog("ByOrderRefer", body).then(el => {
        if (el.status === "success") {
          this.$router.push({
            path: "suggest-map",
            query: { data: JSON.stringify(el.data), date: this.datePicker }
          });
        }
      });
    }
  }
};
</script>
